







































import { ref } from '@vue/composition-api';

import SettingsName from './setting/SettingsName.vue';
import SettingsNotifications from './setting/SettingsNotification.vue';
import ChangeIdentities from './setting/ChangeIdentities.vue';

export default {
  name: 'Settings',

  components: {
    SettingsName,
    SettingsNotifications,
    ChangeIdentities
  },

  setup() {
    const idItems = ref([{ title: 'General', icon: 'mdi-key' }]);
    return { idItems };
  }
};
