

























































































































































































import { defineComponent, reactive, ref, computed, toRefs } from '@vue/composition-api';
import { useUserState, useUserActions } from '@/store';
import axios from 'axios';
import ATeleInput from '@/components/atoms/ATeleInput.vue';

export default defineComponent({
  name: 'ChangeIdentities',
  components: {
    ATeleInput
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  setup(_props, ctx) {
    const { user: userState } = useUserState(['user']);
    const { fetchUser } = useUserActions(['fetchUser']);
    const isPhoneValid = ref(false);
    const show = ref(false);
    const show1 = ref(false);
    const passwordValue = ref('');
    const confirmPassword = ref('');
    const openEmailForm = ref(false);
    const openPhoneForm = ref(false);
    const openPasswordForm = ref(false);
    const isChanging = ref(false);
    const isMailOtpSent = ref(false);
    const msg = ref('');
    const alertType = ref('error');
    const isSending = ref(false);
    const otp = ref('');
    const isPhoneOtpSent = ref(false);

    const user = reactive({
      phoneNumber: '',
      formattedNumber: '',
      email: ''
    });
    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        user.formattedNumber = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        user.formattedNumber = '';
      }
      isPhoneValid.value = val.valid;
    }

    const phoneValid = computed(() => {
      if (isPhoneValid.value && user.phoneNumber) {
        return true;
      }
      if (!isPhoneValid.value && !user.phoneNumber) {
        return true;
      }
      return false;
    });

    const validPassword = computed(() => {
      if (passwordValue.value === confirmPassword.value) {
        return true;
      }
      if (!passwordValue.value.length) {
        return true;
      }
      return false;
    });

    async function forcedPass() {
      isChanging.value = true;
      try {
        const data: any = {
          password: passwordValue.value,
          confirm_password: confirmPassword.value,
          email: userState.value?.email
        };

        if (userState.value?.userId.includes('sms')) {
          const id = userState.value?.identities.filter(i => i.provider === 'auth0')[0];
          data.user_id = `auth0|${id.user_id}`;
        } else {
          data.user_id = userState.value?.userId;
        }
        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/force-password-change`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.status === 200) {
          localStorage.removeItem('apollo-token');
          localStorage.removeItem('apollo-refresh-token');
          window.location.href = `/login?pswChanged=true`;
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function sendOtp(val) {
      msg.value = '';
      alertType.value = '';
      isSending.value = true;
      isMailOtpSent.value = false;
      isPhoneOtpSent.value = false;

      try {
        const data: any = {
          type: val
        };

        if (val === 'email') {
          data.username = user.email;
        } else {
          data.username = user.formattedNumber;
        }

        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/send-otp`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.status === 200) {
          msg.value = resp.data.message;
          alertType.value = 'success';
          if (val === 'email') isMailOtpSent.value = true;
          else isPhoneOtpSent.value = true;
          isSending.value = false;
        }
      } catch (error) {
        console.log(error);
        msg.value = error?.response?.data?.error?.description;
        alertType.value = 'error';
        isSending.value = false;
      }
    }

    async function changeEmail() {
      isChanging.value = true;
      try {
        const data: any = {
          type: 'change_email',
          username: user.email,
          code: otp.value
        };

        if (userState.value?.userId.includes('sms')) {
          const id = userState.value?.identities.filter(i => i.provider === 'auth0')[0];
          data.user_id = `auth0|${id.user_id}`;
        } else {
          data.user_id = userState.value?.userId;
        }

        const resp = await axios.patch(`${process.env.VUE_APP_AUTH0}/change-email-or-phone`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (resp.status === 200) {
          localStorage.removeItem('apollo-token');
          localStorage.removeItem('apollo-refresh-token');
          window.location.href = `/login?emailChanged=true`;
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function changePhone() {
      isChanging.value = true;
      try {
        const data: any = {
          type: 'change_phone_number',
          username: user.formattedNumber,
          code: otp.value
        };

        if (!userState.value?.userId.includes('sms')) {
          const id = userState.value?.identities.filter(i => i.provider === 'sms')[0];
          data.user_id = `sms|${id.user_id}`;
        } else {
          data.user_id = userState.value?.userId;
        }

        const resp = await axios.patch(`${process.env.VUE_APP_AUTH0}/change-email-or-phone`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (resp.status === 200) {
          localStorage.removeItem('apollo-token');
          localStorage.removeItem('apollo-refresh-token');
          window.location.href = `/login?phoneChanged=true`;
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      user,
      fetchUser,
      getValidation,
      changeEmail,
      isSending,
      isPhoneValid,
      changePhone,
      sendOtp,
      phoneValid,
      ...toRefs(user),
      show,
      passwordValue,
      confirmPassword,
      isPhoneOtpSent,
      show1,
      validPassword,
      openEmailForm,
      openPhoneForm,
      userState,
      openPasswordForm,
      isChanging,
      forcedPass,
      isMailOtpSent,
      alertType,
      msg,
      otp,
      permissions
    };
  }
});
